<template>
  <section>
    <app-collapse
      class="collapse-group"
      accordion
      type="border"
      style="margin-bottom: 20px"
    >
      <app-collapse-item title="Filters ">
        <b-row>
          <b-col sm="12" md="12" lg="6">
            <b-input-group class="input-group-merge mb-1">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input placeholder="Search" v-model="search" />
            </b-input-group>
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <v-select
              placeholder="Movie Status"
              label="Movie Status"
              class="mb-1 w-100"
              v-model="activeValue"
              :options="activeOptions"
              :clearable="false"
            />
          </b-col>
          <b-col sm="6" md="2" lg="2">
            <b-button
              variant="primary"
              class="w-100 mb-1"
              @click="onFiltersChange"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              >Search</b-button
            >
          </b-col>
          <b-col sm="6" md="2" lg="2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="w-100 mb-1"
              @click="clearFilters"
              >Reset</b-button
            >
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>

    <b-row class="mb-2 mr-1">
      <b-col class="d-flex align-items-start justify-content-start">
        <b-badge variant="info">Total : {{ this.allMoviesCount }}</b-badge>
      </b-col>
      <b-col class="d-flex align-items-end justify-content-end">
        <b-button-group class="mb-1">
          <b-button variant="outline-info" right @click="downloadCsv">
            Export Movies
          </b-button>
          <b-button
            v-if="$can('create', 'contract')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="gradient-primary"
            @click="$router.push('/movies/form/new')"
            >NEW MOVIE</b-button
          >
        </b-button-group>
      </b-col>
    </b-row>

    <b-card no-body v-if="$can('read', 'movie')">
      <b-table
        style="min-height: 55vh"
        small
        hover
        bordered
        sticky-header
        responsive
        primary-key="id"
        empty-text="No records found"
        show-empty
        :items="moviesList"
        :fields="fields"
        @sort-changed="sortChanged"
        @row-clicked="onRowClicked"
      >
        <template #cell(Sr)="data">
          {{
            data.index +
            1 +
            (pagination.currentPage - 1) * pagination.rowsPerPage
          }}
        </template>
        <template #cell(title)="data">
          <span>{{ data.value }}{{ data.item.draft ? "(draft)" : "" }}</span>
        </template>
        <template #cell(website_visible)="data">
          <b-form-checkbox
            switch
            :checked="data.item.website_visible == '1'"
            @input="onChangeWebsiteVisible($event, data.item.movie_id)"
          />
          <!-- </b-form-group> -->
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              v-if="$can('read', 'movie')"
              icon="EyeIcon"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.left.v-primary
              variant="outline-primary"
              title="View"
              class="mr-1"
              style="cursor: pointer"
              @click="
                () => {
                  $router.push(`/movies/details/${data.item.movie_id}`);
                }
              "
            />

            <feather-icon
              v-if="$can('update', 'movie')"
              icon="EditIcon"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.bottom.v-primary
              variant="outline-primary"
              title="Edit"
              class="mr-1"
              style="cursor: pointer"
              @click="
                () => {
                  $router.push(`/movies/form/${data.item.movie_id}`);
                }
              "
            />
            <feather-icon
              v-if="data.item.archive == 0 && $can('update', 'movie')"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.bottom.v-primary
              variant="outline-primary"
              title="Archive"
              style="cursor: pointer"
              icon="ArchiveIcon"
              @click="
                () => {
                  showModal(
                    'Are you sure want to archive this movie?',
                    'delete'
                  ).then((value) => {
                    if (value) archiveMovie(data.item);
                  });
                }
              "
            />
            <feather-icon
              v-else-if="data.item.archive == 1 && $can('update', 'movie')"
              icon="ChevronsUpIcon"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.bottom.v-primary
              variant="outline-primary"
              title="Unarchive"
              @click="
                showModal(
                  'Are you sure want to unarchive this movie?',
                  'delete'
                ).then((value) => {
                  if (value) unArchiveMovie(data.item);
                })
              "
            />
          </div>
        </template>
      </b-table>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-center justify-content-center justify-content-sm-center"
            >
              <b-pagination
                v-if="allMoviesCount"
                id="movie-pagination"
                v-model="pagination.currentPage"
                :total-rows="allMoviesCount"
                :per-page="pagination.rowsPerPage"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 mt-1 mt-sm-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </section>
</template>

<script>
import {
  BInputGroupPrepend,
  BCard,
  BTable,
  BInputGroupAppend,
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BFormGroup,
  BPagination,
  BInputGroup,
  BFormInput,
  VBTooltip,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import * as MovieServices from "@/apiServices/MovieServices";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import { downloadFromURL } from "@/utils/helpers";
import qs from "qs";
import showModal from "../../components/ModalConfirmation";
import { TokenService } from "@/apiServices/storageService";
import UploadSheets from "@/views/components/UploadSheets.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    FeatherIcon,
    BInputGroupPrepend,
    BCard,
    BTable,
    BInputGroupAppend,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    vSelect,
    VBTooltip,
    BButton,
    UploadSheets,
    BBadge,
    AppCollapseItem,
    AppCollapse,
    BFormCheckbox,
  },
  data() {
    return {
      showModalUpload: false,
      BASE_URL: process.env.VUE_APP_BASEURL,
      pagination: {
        currentPage: 1,
        totalRows: 1,
        rowsPerPage: 25,
        perPageOptions: [10, 25, 50, 100],
      },
      debouce: null,
      search: "",
      activeValue: "Active Movies",
      activeOptions: [
        "Active Movies",
        "Archived Movies",
        "Draft Movies",
        "All Movies",
      ],
      fields: [
        "Sr",
        {
          key: "title",
          label: "Title",
          sortable: true,
        },
        {
          key: "international_title",
          label: "International Title",
          sortable: true,
        },
        {
          key: "unique",
          label: "Unique ID",
          sortable: true,
        },
        {
          key: "year",
          label: "Year",
          sortable: true,
        },
        {
          key: "isan_number",
          label: "ISAN",
          sortable: true,
        },
        {
          key: "website_visible",
          label: "Website",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      moviesList: [],
      allMoviesCount: 0,
      sortableColumns: {
        title: { sortActive: true, sortOrder: "DESC" },
        unique: { sortActive: false, sortOrder: "ASC" },
        year: { sortActive: false, sortOrder: "ASC" },
        isan_number: { sortActive: false, sortOrder: "ASC" },
      },
    };
  },
  methods: {
    onRowClicked(item, index, event) {
      if (this.$can("update", "movie")) {
        this.$router.push(`/movies/form/${item.movie_id}`);
      }
    },
    async onChangeWebsiteVisible(val, movie_id) {
      try {
        await MovieServices.saveMovieWebsiteVisible({
          movie_id,
          website_visible: val,
        });
      } catch (error) {
        console.error(`Error in onChangeWebsiteVisible `, error);
      }
    },
    clearFilters() {
      this.search = "";
      this.activeValue = "Active Movies";
      this.onFiltersChange();
    },

    downloadCsv() {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }

        let urlStr = `${process.env.VUE_APP_BASEURL}/movie/sheet/download?authorization=${token}`;

        downloadFromURL(urlStr, "Movies", "csv");
      } catch (error) {
        console.error(`Error in downloadCSV `, error);
      }
    },

    onFiltersChange() {
      console.log("in onFiltersChange");
      this.pagination.currentPage = 1;
      this.onChange();
    },

    onChange() {
      this.$nextTick(async () => {
        console.log("on change");
        await this.getMovies();

        let obj = {
          search: this.search,
          activeValue: this.activeValue,
          pagination: this.pagination,
        };

        let objStr = JSON.stringify(obj);

        localStorage.setItem("moviesSearchAndPageination", objStr);
      });
    },

    onSearch() {
      if (this.debouce) clearTimeout(this.debouce);
      this.debouce = setTimeout(() => {
        this.onChange();
      }, 1000);
    },

    async archiveMovie(data) {
      try {
        const movie_id = data.movie_id;
        const res = await MovieServices.archiveMovie(movie_id);
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Movie Archived",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.onChange();
        }
      } catch (err) {
        console.error("Error in movie archive", err);
      }
    },

    async unArchiveMovie(data) {
      try {
        const movie_id = data.movie_id;
        const res = await MovieServices.unArchiveMovie(movie_id);
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Movie UnArchived",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.onChange();
        }
      } catch (err) {
        console.log("Error in movie unarchive");
      }
    },

    async getMovies() {
      try {
        let movie_status = {};
        if (this.activeValue == "Active Movies") {
          movie_status = {
            archived: false,
            draft: false,
          };
        } else if (this.activeValue === "Archived Movies") {
          movie_status = {
            archived: true,
            // draft: false,
          };
        } else if (this.activeValue === "Draft Movies") {
          movie_status = {
            archived: false,
            draft: true,
          };
        } else if (this.activeValue == "All Movies") {
          // Empty get all
          movie_status = {};
        }

        const payload = {
          search: this.search,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.currentPage,
          sort: this.sortableColumns,
          ...movie_status,
        };

        const response = await MovieServices.getAllMovie(payload);

        if (response.data.status) {
          this.moviesList = response.data.data.data;
          this.allMoviesCount = response.data.data.count;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },

    showModal,

    async downloadExcel() {
      let queryObj = {
        search: this.search,
        sort: this.sortableColumns,
      };

      if (this.activeValue == "Active Movies") {
        queryObj = {
          ...queryObj,
          archived: false,
          draft: false,
        };
      } else if (this.activeValue === "Archived Movies") {
        queryObj = {
          ...queryObj,
          archived: true,
          draft: false,
        };
      } else if (this.activeValue === "Draft Movies") {
        queryObj = {
          ...queryObj,
          archived: false,
          draft: true,
        };
      } else if (this.activeValue == "All Movies") {
        // Empty get all
        // queryObj = { ...queryObj };
      }

      const queryString = qs.stringify(queryObj);

      fetch(this.BASE_URL + `/movie/downloadExcel?${queryString}`, {
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var _url = window.URL.createObjectURL(blob);
          downloadFromURL(
            _url,
            this.activeValue === "Archived Movies"
              ? "ArchivedMovies"
              : "Movies",
            "xlsx"
          );
        })
        .catch((err) => {
          console.error(err);
        });
    },

    sortChanged(ctx) {
      this.sortableColumns[ctx.sortBy] = {
        sortActive: true,
        sortOrder: ctx.sortDesc ? "DESC" : "ASC",
      };

      Object.keys(this.sortableColumns).map((key) => {
        if (key !== ctx.sortBy) {
          this.sortableColumns[key].sortActive = false;
        }
      });

      this.onChange();
    },

    loadMovies() {
      const objStr = localStorage.getItem("moviesSearchAndPageination");
      if (objStr) {
        let obj = JSON.parse(objStr);
        this.search = obj.search;
        this.activeValue = obj.activeValue;
        this.pagination = obj.pagination;
      }
      this.getMovies();
    },
  },
  beforeMount() {
    setTimeout(() => {
      this.loadMovies();
    });
  },
};
</script>
<style scoped>
@media only screen and (max-width: 300px) {
  .search-list {
    margin-bottom: -10px;
  }
}
</style>
